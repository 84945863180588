import React from 'react'

type EnvironmentSelectorProps = {
  value: string
  options: { id: string, name: string, value: string }[]
  onChange: (value: string) => void
}

const EnvironmentSelector: React.FC<EnvironmentSelectorProps> = ({ value, options, onChange }) => {
  React.useEffect(() => {
    const keys = Object.keys(options)

    if (keys.length === 1) {
      keys.forEach(key => onChange((options as any)[key].value))
      
    }
  }, [options, onChange])

  return (
    <div className="EnvironmentSelector inputSection">
      <div className="RadioGroup">
        {options.map(({ id, name, value: optionValue }) => (
          <label key={id}>
            <input
              type="radio"
              value={optionValue}
              checked={optionValue === value}
              onChange={e => onChange(e.target.value)}
            />
            <span>{name}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default EnvironmentSelector