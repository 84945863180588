import React from 'react'

const ratio = 281 / 70

interface Props {
  height: number
}

const Logo: React.FC<Props> = ({ height }) => {
  const logoTextColor = '#fff'
  const width = height * ratio

  return (
    <div>
      <svg width={width} height={height} viewBox="0 0 281 70" version="1.1">
        <title>LOGR AIR logo</title>
        <defs>
          <path d="M214.201,49 L218.92,41.707 L234.169,41.707 L236.002,49 L238.888,49 L231.868,21.7 L229.021,21.7 L211.081,49 L214.201,49 Z M233.623,39.367 L220.402,39.367 L229.918,24.625 L233.623,39.367 Z M246.259,49 L251.719,21.7 L248.872,21.7 L243.412,49 L246.259,49 Z M258.076,49 L259.792,40.342 L267.788219,40.3413906 C268.317156,40.3385469 268.70675,40.32575 268.957,40.303 L268.957,40.303 L273.871,49 L276.991,49 L271.843,39.874 C274.495,39.25 276.5555,38.0475 278.0245,36.2665 C279.4935,34.4855 280.228,32.204 280.228,29.422 C280.228,26.978 279.3765,25.08 277.6735,23.728 C275.9705,22.376 273.572,21.7 270.478,21.7 L270.478,21.7 L260.65,21.7 L255.19,49 L258.076,49 Z M267.631,37.846 L260.26,37.846 L263.029,24.196 L270.283,24.196 C272.545,24.196 274.287,24.664 275.509,25.6 C276.731,26.536 277.342,27.901 277.342,29.695 C277.342,32.373 276.5035,34.401 274.8265,35.779 C273.1495,37.157 270.751,37.846 267.631,37.846 L267.631,37.846 Z" id="path-1"></path>
        </defs>
        <g id="LOGR-AIR-logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="LOGR-logo">
            <g id="Logr_Horizontal-Lock-up">
              <path d="M188.64,39.69 L194.29,48.69 L186.53,48.69 L182.53,41.02 L179.36,41.02 L179.36,35.56 L182.36,35.56 C185.36,35.56 187.05,34.04 187.05,31.16 C187.05,28.28 185.32,26.87 182.36,26.87 L177.17,26.87 L177.17,35.56 L177.17,35.56 L177.17,41 L177.17,41 L177.17,48.67 L170.33,48.67 L170.33,21.42 L182.33,21.42 C189.44,21.42 193.51,24.85 193.51,30.93 C193.52,35.1 191.79,38.1 188.64,39.69 Z M148.64,34.82 L157.86,34.82 L157.86,45.46 C154.545422,47.6861133 150.661833,48.9147017 146.67,49 C138.48,49 132.3,43 132.3,35.05 C132.3,27.1 138.64,21.1799391 147.13,21.1799391 C151.154551,21.1692339 155.052405,22.5866354 158.13,25.18 L154.28,30 C152.329573,28.1563439 149.772396,27.0893717 147.09,27 C144.97367,26.9987317 142.947668,27.8574742 141.476869,29.3791855 C140.00607,30.9008969 139.216742,32.9549377 139.29,35.07 C139.213844,37.2061561 140.010968,39.2811984 141.497692,40.8169766 C142.984416,42.3527548 145.032498,43.2167961 147.17,43.21 C148.83407,43.1231717 150.458874,42.6725957 151.93,41.89 L151.93,40.4 L148.63,40.4 L148.64,34.82 Z M101.17,35.06 C101.023948,39.4091284 104.430892,43.0533493 108.78,43.2 C112.93,43.2 116.24,39.66 116.24,35.06 C116.24,30.46 112.92,27 108.77,27 C106.687721,27.0468567 104.710922,27.9256204 103.281039,29.4400566 C101.851156,30.9544928 101.087283,32.9784632 101.16,35.06 L101.17,35.06 Z M123.27,35.06 C123.27,43.01 117.04,49.06 108.7,49.06 C100.36,49.06 94.17,43 94.17,35 C94.17,27 100.39,21.17 108.69,21.17 C116.99,21.17 123.26,27.11 123.26,35 L123.27,35.06 Z M73.57,42.93 L85,42.93 L85,48.7 L66.72,48.7 L66.72,27 L65.09,27 L65.09,21.41 L73.56,21.41 L73.57,42.93 Z" id="Shape" fill={logoTextColor}></path>
              <path d="M36.57,52.05 C38.81,48.17 40.49,42.63 41.72,36.62 C34.8408894,30.3046775 24.416684,29.8512786 17.0152217,35.5454702 C9.61375951,41.2396618 7.3794355,51.4316894 11.72,59.7 C20.7263457,64.040236 31.5558314,60.7050683 36.56,52.05" id="Path" fill="#321F88"></path>
              <path d="M41.72,36.62 C40.49,42.62 38.81,48.17 36.57,52.05 C31.5658314,60.7050683 20.7363457,64.040236 11.73,59.7 C15.2894762,66.480421 22.536099,70.4990542 30.1726706,69.9274302 C37.8092421,69.3558061 44.3768458,64.3031297 46.8872358,57.068358 C49.3976258,49.8335863 47.3712087,41.7988748 41.73,36.62" id="Path" fill="#16E0E3"></path>
              <path d="M28.73,31.56 C33.5455788,31.5512219 38.1875074,33.3580033 41.73,36.62 C45.02,20.62 45.13,1.14 44.22,0.62 C42.74,-0.23 12,18.29 3.48,33 C-1.7268376,42.1154719 1.39416959,53.7243685 10.47,59 C10.89,59.24 11.31,59.46 11.73,59.66 C8.60421102,53.7065777 8.81878754,46.5521341 12.295748,40.7967419 C15.7727085,35.0413496 22.0058803,31.5228734 28.73,31.52" id="Path" fill="#46E85D"></path>
            </g>
          </g>
          <g id="AIR" fillRule="nonzero">
            <use fill="#6E6D6D" href="#path-1"></use>
            <use fill="#414042" href="#path-1"></use>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo