import React from 'react'
import { GlobalContext } from '../../state/reducer'
import useWeighbridgesInfo from '../../hooks/useWeighbridgesInfo'
import SessionDetailView from '../SessionDetailView/SessionDetailView'

const SessionsView = () => {
  const { state } = React.useContext(GlobalContext)
  const { selectedEnvironment, environmentData } = state
  const env = environmentData.find(item => item.id === selectedEnvironment?.id)
  const sessions = env?.activeSessions?.sessions
  const weighbridges = useWeighbridgesInfo()

  return (
    <div className="SessionsView">
      <div className="Active">
        {sessions && sessions.map(item => {
          let name = item.state.uid
          const weighbridgeInfo = weighbridges.find(bridge => bridge.uid === item.state.uid)

          if (weighbridgeInfo) {
            name = weighbridgeInfo.name || weighbridgeInfo.uid
          }

          return <SessionDetailView key={item.uid} activeSession={item} name={name} />
        })}
      </div>
    </div>
  )
}

export default SessionsView