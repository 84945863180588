import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAVmeorExINKjKJyN-IVTDO7Sc6U9C8Yc4',
  authDomain: 'logr-air.firebaseapp.com',
  databaseURL: 'https://logr-air.firebaseio.com',
  projectId: 'logr-air',
  storageBucket: 'logr-air.appspot.com',
  messagingSenderId: '184791397343',
  appId: '1:184791397343:web:3fcbcf8ee92ad4385b8a6a'
}, 'logr-air-system-monitor')

export default firebaseApp