import React from 'react'
import { ActiveSessionResponse } from '../../SocketMessage'
import moment from 'moment'
import yml from 'json-to-pretty-yaml'

type SessionsPanelProps = {
  response?: ActiveSessionResponse
}


const SessionsPanel: React.FC<SessionsPanelProps> = ({ response }) => {
  const isFresh = response && moment(response.iat).isAfter(moment().subtract(1, 'minute'))

  const [ticker, setTicker] = React.useState(0)
  React.useEffect(() => {
    const id = setTimeout(() => setTicker(Date.now()), 1000)
    return () => clearTimeout(id)
  }, [ticker])

  return (
    <div>
      <h2>Global Sessions</h2>

      {response && (
        <div className="queue Code">
          <span className={`tag ${isFresh ? 'success' : 'warning'}`}>Updated {moment(response.iat).fromNow()}</span>
          {response.sessions.map(item => (
            <p key={item.uid}>
              {`${yml.stringify(item)}`}
            </p>
          ))}
        </div>
      )}

      {!response && <p><em>No data.</em></p>}
    </div>
  )
}

export default SessionsPanel