import React from 'react'
import { ActiveSession } from '../../SocketMessage'
import './NodeView.scss'
import moment from 'moment-timezone'
import {
  measurementsToString,
  cameraIdentityToUri,
  nonCameraIdentityToUri,
} from './helpers'

type NodeViewProps = {
  name: string
  session: ActiveSession
}

const Graph = (session: ActiveSession) => {
  const { clients } = session
  const { stableWeight, availableClaim, identitiesReceived, claimant, confirmedClaim, receipt } = session.state

  const duration = moment().tz('Australia/Adelaide').diff(moment(stableWeight?.measure_at).tz('Australia/Adelaide'))

  const connected = true
  const connectedAt = ~~(duration / 1000 / 60) + ' mins' + ' ' + ~~((duration / 1000) % 60) + ' secs'
  const isWeightReceived = stableWeight !== undefined
  const weightValue = stableWeight ? measurementsToString(stableWeight.measurements) : null
  const isClaimAvailable = availableClaim !== undefined
  const claimValue = availableClaim ? 'Yes' : 'No'
  const actualIdentitiesReceived = identitiesReceived ? identitiesReceived.filter(item => item.identities.length > 0) : []
  const isClaimed = claimant !== undefined
  const claimantValue = claimant?.name.split('@')[0]
  const isConfirmed = confirmedClaim !== undefined
  const confirmedValue = confirmedClaim ? [
    confirmedClaim.docket_id,
    confirmedClaim.source_name,
    confirmedClaim.supplier_name,
    confirmedClaim.driver_name,
    confirmedClaim.hauler_name,
    confirmedClaim.rego,
    confirmedClaim.weight_attribution_order
  ].join("\n") : ''
  const hasReceipt = receipt !== undefined
  const receiptValue = receipt ? receipt.receipt_weights.reduce((prev, next) => {
    return prev + ' ' + measurementsToString(next.measurements)
  }, '') : null

  type Row = { title: string, value: any, enabled: boolean }

  const clientRows: Row[] = clients ? clients.map(item => ({ title: item.type, value: item.name.split('@')[0], enabled: true })) : []
  const nonCameraRows: Row[] = actualIdentitiesReceived
    .filter(item => nonCameraIdentityToUri(item).length > 0)
    .map(item => {
      const idsSource = nonCameraIdentityToUri(item)
      const ids = idsSource.map(({ source, value }) => {
        return (
          <div className="NonCameraId" key={`${source}-${value}`}>
            <div>
              <span>{source}</span>
              <span>{value}</span>
            </div>
          </div>
        )
      })
      return ({ title: 'Identity: Other', value: ids, enabled: idsSource.length > 0 })
    })

  const cameraRows: Row[] = actualIdentitiesReceived
    .filter(item => cameraIdentityToUri(item).length > 0)
    .map(item => {
      const cameraIdsSource = cameraIdentityToUri(item)
      const cameraIds = cameraIdsSource.map(({ source, value, uri }) => {
        return (
          <div className="CameraId" key={uri}>
            <img src={uri} alt={source} />
            <div>
              <span>{source}</span>
              <span>{value}</span>
            </div>
          </div>
        )
      })

      return ({ title: 'Identity: Camera', value: cameraIds, enabled: cameraIdsSource.length > 0 })
    })

  const rows: Row[] = [
    { title: 'Connected', value: connectedAt, enabled: connected },
    { title: 'Weight', value: weightValue, enabled: isWeightReceived },
    ...nonCameraRows,
    ...cameraRows,
    { title: 'Claim Available', value: claimValue, enabled: isClaimAvailable },
    { title: 'Claimed', value: claimantValue, enabled: isClaimed },
    { title: 'Confirmed', value: confirmedValue, enabled: isConfirmed },
    { title: 'Receipt', value: receiptValue, enabled: hasReceipt },
  ]

  const enabled = rows.filter(item => item.enabled)
  const notEnabled = rows.filter(item => !item.enabled)

  return (
    <div className="Graph">
      <div className="Clients">
        {clientRows.map(item => <span key={item.value} className={item.title}>{item.value}</span>)}
      </div>
      {[
        ...enabled,
        ...notEnabled,
      ].map(({ title, value, enabled }, index) => (
        title === 'Identity: Camera' ? (
          <div className="Event" key={index}>
            {value && <span className="Value">{value}</span>}
          </div>
        ) :
        <div className="Event" key={index}>
          <span className={`Circle ${enabled ? '' : 'Disabled'}`}></span>
          <span className="Title">{title}</span>
          {value && <span className="Value">{value}</span>}
        </div>
      ))}
    </div>
  )
}

const NodeView: React.FC<NodeViewProps> = ({ name, session }) => (
  <div className="NodeView">
    <Graph {...session} />
  </div>
)

export default NodeView