import React from 'react'
import { ActiveSession } from '../../SocketMessage'
import yml from 'json-to-pretty-yaml'
import moment from 'moment'
import { WeightMeasurement } from 'logr-air-lib'
import useLocalStorage from '../../hooks/useLocalStorage'
import NodeView from '../NodeView/NodeView'

const ExpandableThingo = ({ name, text, value }: { name: string, text: any, value: any }) => {
  const [open, setOpen] = React.useState(false)
  const humanReadable = yml.stringify(value)

  return (
    <div>
      <span className="ExpandableThingoItem" title={humanReadable} onClick={() => setOpen(!open)}>
        {name && name.length > 0 && <span>{name}</span>}
        <span>{text}</span>

      </span>
      {open && <div className="Code">{humanReadable}</div>}
      {open && (
        <div className="ImageContainer">
          {value && value?.identities?.map((identity: any) => {
            return identity?.references?.filter((reference: any) => reference.type === 'image_uri')
              .map((reference: any) => <img key={reference.value} src={reference.value} alt={reference.value} />)
          })}
        </div>
      )}
    </div>
  )
}

const toSeconds = (num: number | string | undefined) => {
  if (typeof num === 'number')
    return num / 1000

  if (typeof num === 'string')
    return parseInt(num) / 1000

  return num
}

const measurementsToString = (measurements: WeightMeasurement[]) => measurements.reduce((prev, next) => [...prev, `${next.weight}${next.unit} (${next.type})`], [] as string[]).join(',')

type SessionDetailViewType = {
  activeSession: ActiveSession
  name: string
}

const SessionDetailView: React.FC<SessionDetailViewType> = ({ activeSession, name }) => {
  const [expanded, setExpanded] = useLocalStorage(`SessionDetailView:${activeSession.uid}`, false)
  const {
    confirmedClaim,
    endTime,
    identitiesReceived,
    identityRequests,
    receipt,
    stableWeight,
    state,
    transactionId,
    timeoutDurations,
    claimantSocketId,
  } = activeSession.state
  const { clients } = activeSession

  const [ticker, setTicker] = React.useState(0)
  React.useEffect(() => {
    const id = setTimeout(() => setTicker(Date.now()), 1000)
    return () => clearTimeout(id)
  }, [ticker])

  return (
    <div className="SessionDetailView">
      <div className="Header" onClick={() => setExpanded(!expanded)}>
        <div className="UID">{name}</div>
      </div>

      {expanded && (
        <div className="Content">
          <div className="SessionData">
            <span className={`State ${state}`}>{state}</span>
            <ExpandableThingo name="Start Time " text={stableWeight?.measure_at ? moment(stableWeight.measure_at).format('LTS') + ` (${moment(stableWeight.measure_at).fromNow()})`: '(NA)'} value={stableWeight?.measure_at} />
            <ExpandableThingo name="Transaction ID " text={transactionId} value={transactionId} />
            <ExpandableThingo name="SENT: ID Requests " text={`(${identityRequests?.length})`} value={identityRequests} />
            <ExpandableThingo name="RECV: Identities " text={`(${identitiesReceived?.length})`} value={identitiesReceived} />

            <ExpandableThingo name="RECV: Stable Weight " text={stableWeight ? `${measurementsToString(stableWeight.measurements)}, Identities (${stableWeight.identities?.length || 0})` : '(NA)'} value={stableWeight} />
            <ExpandableThingo name="SYS: Claimant " text={claimantSocketId ? `Driver Claimed ${claimantSocketId}` : '(NA)'} value={claimantSocketId} />
            <ExpandableThingo name="RECV: Confirmation " text={confirmedClaim ? `(${moment(confirmedClaim.confirmed_at_timestamp).fromNow()})` : '(NA)'} value={confirmedClaim} />

            <ExpandableThingo name="SYS: Docket " text={confirmedClaim ? confirmedClaim.docket_id : '(NA)'} value={confirmedClaim} />
            <ExpandableThingo name="RECV: Receipt " text={receipt ? receipt.receipt_id : '(NA)'} value={receipt} />

            <ExpandableThingo name="SYS: Timeout Durations " text={timeoutDurations ? `ident ${toSeconds(timeoutDurations.requestIdentity)}s, confirm ${toSeconds(timeoutDurations.driverConfirm)}s, receipt ${toSeconds(timeoutDurations.weighbridgeReceipt)}s` : '(NA)'} value={timeoutDurations} />
            <ExpandableThingo name="End Time " text={endTime ? moment(endTime).format('LTS') : '(NA)'} value={endTime} />
          </div>
          <div className="ClientList">
            {clients?.map(client => (
              <ExpandableThingo
                key={client.uid}
                name=""
                text={`${client.name} (${client.type})`}
                value={client}
              />
            ))}
          </div>
        </div>
      )}

      <NodeView session={activeSession} name={name} />

    </div>
  )
}

export default SessionDetailView