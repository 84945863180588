import React from 'react'
import { QueueResponse, ActiveSessionResponse, UPDATED_QUEUE, UPDATED_SESSIONS, CONSOLE, VERSION, ConsoleMessage, ActiveSession, Client, DEBUG_UPDATED_SESSION, DEBUG_UPDATED_SESSION_CLIENTS } from '../SocketMessage'
import { UserDetails } from '../types'

export type Environment = { id: string, value: string, name: string }
export const WebsocketUrls: Environment[] = [
  // { id: '0', value: 'ws://localhost:8080', name: 'Local' },
  // { id: '1', value: 'wss://monitor-service.air.logr.com.au/staging', name: 'Staging' },
  { id: '2', value: 'wss://monitor-service.air.logr.com.au', name: 'Production' },
]

export const STORAGE_API_TOKEN = 'logr-air-system-monitor-token'
export const STORAGE_ENVIRONMENT = 'logr-air-system-monitor-environment'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const USER_DETAILS_FETCHED = 'USER_DETAILS_FETCHED'

type EnvironmentData = {
  id: string
  version?: string
  syncVersion?: string
  messages: any[] // SocketMessage[]
  consoleMessages: ConsoleMessage[]
  queue?: QueueResponse
  activeSessions?: ActiveSessionResponse
  deadSessions: ActiveSession[]
}

const initialEnvironmentData: EnvironmentData = {
  id: 'unknown',
  version: '',
  syncVersion: '',
  messages: [],
  consoleMessages: [],
  deadSessions: [],
}

export type GlobalState = {
  expectSignedIn: boolean
  selectedEnvironment?: Environment
  selectedToken?: string
  environmentData: EnvironmentData[]
  user?: firebase.User
  firebaseToken?: string
  userDetails?: UserDetails
}

export const initialState: GlobalState = {
  expectSignedIn: localStorage.getItem('logr-air-system-monitor:expectSignedIn') === 'true',
  environmentData: [],
}

type GlobalContext = {
  state: GlobalState
  dispatch: (action: Actions) => void
}

export const GlobalContext = React.createContext<GlobalContext>({
  state: initialState,
  dispatch: (action: Actions) => { },
})

export type Actions = { type: typeof VERSION, data: { version: string, sync: string } }
  | { type: typeof CONSOLE, data: any }
  | { type: 'environment', data: Environment }
  | { type: 'token', data: string }
  | { type: typeof UPDATED_QUEUE, data: QueueResponse }
  | { type: typeof UPDATED_SESSIONS, data: ActiveSessionResponse }
  | { type: typeof DEBUG_UPDATED_SESSION, data: { iat: number, uid: string, session: ActiveSession } }
  | { type: typeof DEBUG_UPDATED_SESSION_CLIENTS, data: { iat: number, uid: string, clients: Client[] } }
  | { type: typeof SIGN_IN, user: firebase.User, firebaseToken: string }
  | { type: typeof SIGN_OUT }
  | { type: typeof USER_DETAILS_FETCHED, userDetails: UserDetails }

const authReducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.user,
        firebaseToken: action.firebaseToken,
      }

    case SIGN_OUT:
      return { ...initialState, expectSignedIn: false }
    
    case USER_DETAILS_FETCHED:
      return {
        ...state,
        userDetails: {...action.userDetails},
      }
  }

  return state
}

const environmentDataReducer = (prevState: EnvironmentData, action: Actions): EnvironmentData => {
  const state = { ...prevState }

  if (action.type !== 'console') {
    state.messages = [...state.messages, action]
  }

  switch (action.type) {
    // Updates the state for a specific session
    case DEBUG_UPDATED_SESSION:
      const existing = state?.activeSessions?.sessions.find(item => item.uid === action.data.uid)

      return {
        ...state,
        activeSessions: state.activeSessions ? {
          ...state.activeSessions,
          iat: action.data.iat,
          sessions: [
            ...state.activeSessions.sessions.filter(item => item.uid !== action.data.uid),
            existing ? { ...existing, ...action.data.session } : {...action.data.session }
          ]
        } : undefined
      }

    // Updates the client list for a specific session
    case DEBUG_UPDATED_SESSION_CLIENTS:
      return {
        ...state,
        activeSessions: state.activeSessions ? {
          ...state.activeSessions,
          iat: action.data.iat,
          sessions: state.activeSessions.sessions.map(item => {
            if (item.uid === action.data.uid) {
              return {
                ...item,
                clients: [...action.data.clients],
              }
            }
            
            return item
          })
        } : undefined
      }

    // Updated global queue (aka devices waiting for a weighbridge session)
    case UPDATED_QUEUE:
      return {
        ...state,
        queue: action.data
      }

    // Updated global weighbridge sessions
    case UPDATED_SESSIONS:
      const replacementTransactionIds = action.data.sessions.map(item => item.state.transactionId)
      const deadSessions = state.activeSessions ? state.activeSessions.sessions.filter(item => !replacementTransactionIds.includes(item.state.transactionId)) : []

      return {
        ...state,
        activeSessions: action.data,
        deadSessions: [...state.deadSessions, ...deadSessions],
      }

    case VERSION:
      return {
        ...state,
        version: action.data.version,
        syncVersion: action.data.sync,
      }

    case CONSOLE:
      return {
        ...state,
        consoleMessages: [
          ...state.consoleMessages,
          action
        ]
      }
  }

  return state
}

const mainReducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case 'environment':
      return { ...state, selectedEnvironment: action.data }

    case 'token':
      return { ...state, selectedToken: action.data }
  }

  if (state.selectedEnvironment) {
    const id = state.selectedEnvironment.id
    const filtered = state.environmentData.filter(item => item.id !== id)
    const matched = state.environmentData.find(item => item.id === id) || { ...initialEnvironmentData, id };

    // if (action.type !== 'console') {
    //   (notifier as any).success(`${action.type}`);
    // }

    return {
      ...state,
      environmentData: [
        ...filtered,
        environmentDataReducer(matched, action)
      ]
    }
  }

  return state
}


const reducers = [
  authReducer,
  mainReducer,
]

export const reducer = (state: GlobalState, action: Actions): GlobalState => {
  return reducers.reduce((prevState, nextReducer) => nextReducer(prevState, action), state)
}