import React from 'react'
import useWbcsClient from '../hooks/useWbcsClient'
import './Indicator.scss'

type IndicatorProps = {
  token?: string
  weighbridgeUid: string
  name?: string
}

const Indicator: React.FC<IndicatorProps> = ({ token, weighbridgeUid, name: nameFirestore }) => {
  const {
    authenticated,
    line,
    info,
    status,
    lastInfoAt,
    lastMessageAt,
    lastWeightAt,
  } = useWbcsClient(weighbridgeUid, token, 60000)

  const name = React.useMemo(() => nameFirestore || info?.WEIGHBRIDGE_NAME.replace(/\./g, ' ') || weighbridgeUid, [nameFirestore, info, weighbridgeUid])

  const excludes = [
    'iWeigh (Test)',
    'Visy Tumut (Development)',
    'Enabled (Development)'
  ]

  if (excludes.includes(name)) {
    return <></>
  }

  return (
    <div className={`Indicator ${status}`} title={weighbridgeUid}>
      <span className="StatusRow">
        <span className="WeighbridgeName">{name}</span>
      </span>

      {/* <pre style={{ width: 300, height: 200 }}>{JSON.stringify(info, null, 2)}</pre> */}

      <span className="IndicatorView">{authenticated && line}</span>

      {info && <span className="Tags">
        <span>stable {info.MINIMUM_STABLE_DURATION_MILLISECONDS/1000}s</span>
        <span>zero: +/-{info.ZERO_TOLERANCE}{info.INDICATOR_UNIT}</span>
        <span>{info.ALLOW_MULTIPLE_STABLE_WEIGHTS === true ? 'multi-weight' : 'single-weight'}</span>
      </span>}
    </div>
  )
}

export default Indicator