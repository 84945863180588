import React from 'react'
import { GlobalContext } from '../state/reducer'
import { Route, Redirect, RouteProps } from 'react-router-dom'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { state } = React.useContext(GlobalContext)
  const { user } = state

  const render = React.useCallback(({ location }) => user ? children : (
    <Redirect to={{ pathname: "/login", state: { from: location } }} />
  ), [children, user])

  return <Route {...props} render={render} />
}

export default PrivateRoute