import React from 'react'
import './Monitor.scss'
import { useAirSocketFirebaseUser } from '../../hooks/useAirSocket'
import useLocalStorage from '../../hooks/useLocalStorage'
import ReadyState from './ReadyState'
import EnvironmentSelector from './EnvironmentSelector'
import useDebounce from '../../hooks/useDebounce'
import ConsoleLogs from './ConsoleLogs'
import ErrorPanel from './ErrorPanel'
import QueuePanel from './QueuePanel'
import SessionsPanel from './SessionsPanel'
import SessionsView from './SessionsView'
import { GlobalContext, STORAGE_ENVIRONMENT, STORAGE_API_TOKEN, WebsocketUrls } from '../../state/reducer'
import moment from 'moment'
import Indicator from '../../components/Indicator'
import jwt from 'jsonwebtoken'
import useWeighbridgesInfo, { Weighbridge } from '../../hooks/useWeighbridgesInfo'

moment.updateLocale('en', {
  relativeTime: {
    s: '%d secs',
  }
})

const IndicatorContainer = ({ memoWeighbridges, userToken }: { memoWeighbridges: string, userToken?: string }) => {
  const weighbridges: Weighbridge[] = React.useMemo(() => JSON.parse(memoWeighbridges), [memoWeighbridges])

  return (
    <div className="IndicatorContainer">
      {weighbridges.map(({ uid, name }) => <Indicator key={uid} weighbridgeUid={uid} token={userToken} name={name} />)}
    </div>
  )
}

const Monitor: React.FC = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { user } = state
  const [url, setUrl] = useLocalStorage(STORAGE_ENVIRONMENT, '')
  const [token, setToken] = useLocalStorage(`${STORAGE_API_TOKEN}-${url}`, '')
  const debouncedToken = useDebounce(token, 500)
  const debouncedUrl = useDebounce(url, 500)
  const [msg, readyState, , , error] = useAirSocketFirebaseUser(user, debouncedUrl)
  const { selectedEnvironment, environmentData } = state
  const environment = environmentData.find(item => item.id === selectedEnvironment?.id)
  const { version, syncVersion, messages, consoleMessages, queue, activeSessions } = environment || {}
  const weighbridges = useWeighbridgesInfo()
  // const [weighbridgeUids, setWeighbridgeUids] = React.useState<string>('[]')

  const memoWeighbridges = React.useMemo(() => {
    return JSON.stringify([...weighbridges].sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '')
    }))
  }, [weighbridges])

  // React.useEffect(() => {
  //   const uids = weighbridges.map(item => item.uid)

  //   setWeighbridgeUids(prev => {
  //     const prevArr = JSON.parse(prev) as string[]
  //     const uniqueArr = [...new Set([...uids, ...prevArr]) as any]
  //     const sortedReplacement = uniqueArr.sort((a, b) => a.localeCompare(b))
  //     return JSON.stringify(sortedReplacement)
  //   })
  // }, [weighbridges])

  React.useEffect(() => {
    const environment = WebsocketUrls.find(item => item.value === debouncedUrl)

    if (environment) {
      dispatch({ type: 'environment', data: environment })
      dispatch({ type: 'token', data: debouncedToken })
    }
  }, [debouncedToken, debouncedUrl, dispatch])

  React.useEffect(() => {
    if (msg) {
      dispatch(msg as any)
    }
  }, [msg, dispatch])

  // Refreshes Firebase Token -- Start
  const [userToken, setUserToken] = React.useState<string>()
  const [userTokenReload, setUserTokenReload] = React.useState(0)

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined
    const tokenReloadCopy = userTokenReload

    if (user) {
      user
        .getIdToken()
        .then(val => {
          if (tokenReloadCopy === userTokenReload) {
            setUserToken(val)
            const decoded: any = jwt.decode(val)

            if (decoded && decoded.exp) {
              setTimeout(() => {
                setUserTokenReload((prev: number) => prev = 1)
              }, (decoded.exp * 1000) - Date.now())
            }
          }
        })
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [user, userTokenReload])
  // Refreshes Firebase Token -- End

  return (
    <div className="Monitor">
      <div className="Content">
        <div className="StatusTags">
          <EnvironmentSelector value={url} onChange={setUrl} options={WebsocketUrls} />
          <ReadyState readyState={readyState} />
          {version && <span className="tag success">{version}, Sync {syncVersion}</span>}
          <ErrorPanel error={error} />
        </div>

        <h2>Live Indicators</h2>
        <IndicatorContainer memoWeighbridges={memoWeighbridges} userToken={userToken}/>

        <h2>Active Sessions</h2>
        <SessionsView />

        <div className="Globals">
          <QueuePanel response={queue} />
          <SessionsPanel response={activeSessions} />
        </div>

        <ConsoleLogs logs={consoleMessages || []} />
        {/* <MessageLogPanel messages={messages || []} /> */}
      </div>
    </div>
  );
}

export default Monitor