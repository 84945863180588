import React from 'react'

/**
 * Value returned doesn't update till after the duration has expired.
 * Duration timer resets upon input value change.
 * 
 * @param value 
 * @param duration 
 */
const useDebounce = (value: any, duration: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), duration)
    return () => clearTimeout(id)
  }, [value, duration])

  return debouncedValue
}

export default useDebounce