import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

type SignInFunction = (email: string, password: string) => void
type SignOutFunction = () => void
type FetchingState = boolean
type ErrorMessage = Error | undefined

type useFirebaseAuthType = (app: firebase.app.App, waitTimeout: number) => [
  firebase.User | undefined,
  string | undefined,
  SignInFunction,
  SignOutFunction,
  FetchingState,
  ErrorMessage
]

const useFirebaseAuth: useFirebaseAuthType = (app: firebase.app.App, waitTimeout: number) => {
  const [user, setUser] = React.useState<firebase.User>() // app.auth().currentUser
  const [firebaseToken, setFirebaseToken] = React.useState<string>()
  const [error, setError] = React.useState<Error>()
  const [waitTimeoutFetching, setWaitTimeoutFetching] = React.useState(true)
  const [fetching, setFetching] = React.useState(true)

  React.useEffect(() => {
    if (!user) {
      const timeoutId = setTimeout(() => setWaitTimeoutFetching(false), waitTimeout)
      return () => clearTimeout(timeoutId)
    }
  }, [user, waitTimeout])

  React.useLayoutEffect(() => {
    setFetching(true)

    app.auth()
      .onAuthStateChanged(async (authUser) => {
        setError(undefined)
        setFetching(false)
        setUser(authUser ?? undefined)

        if (authUser) {
          setFirebaseToken(await authUser?.getIdToken())
        } else {
          setFirebaseToken(undefined)
        }
      })
  }, [app])

  const signIn = (email: string, password: string) => {
    setError(undefined)
    setFetching(true)

    app.auth()
      .signInWithEmailAndPassword(email, password)
      .catch(setError)
      .finally(() => setFetching(false))
  }

  const signOut = () => {
    setError(undefined)
    setFetching(true)

    app.auth()
      .signOut()
      .then(() => setUser(undefined))
      .catch(setError)
      .finally(() => setFetching(false))
  }

  return [user, firebaseToken, signIn, signOut, fetching || waitTimeoutFetching, error]
}

export default useFirebaseAuth