import React from 'react'
import classNames from 'classnames'

import './TextInput.scss'

type TextInputProps = {
  label?: string
  placeholder?: string
  value: string
  enabled: boolean
  onChange: (val: string) => void
  orientation?: 'vertical' | 'horizontal'
  type?: 'password' | 'text'
  inputStyle?: 'rounded'
}

const TextInput: React.FC<TextInputProps> = ({ label, value, enabled, onChange, orientation, type, placeholder, inputStyle }) => (
  <label className={classNames('TextInput', orientation ?? 'vertical', inputStyle)}>
    {label && <span className="label">{label}</span>}
    <input
      type={type ?? 'text'}
      disabled={!enabled}
      value={value ?? ''}
      placeholder={placeholder ?? label}
      onChange={e => onChange(e.target.value)}
    />
  </label>
)

export default TextInput