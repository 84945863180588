import React from 'react'
import './App.scss'
import { GlobalContext, reducer, initialState } from './state/reducer'
import { BrowserRouter as Router } from 'react-router-dom'
import LogrAirSystemMonitor from './LogrAirSystemMonitor'

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <Router>
        <LogrAirSystemMonitor />
      </Router>
    </GlobalContext.Provider>
  )
}

export default App