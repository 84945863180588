import React from 'react'
import { ConsoleMessage } from '../../SocketMessage'

const ConsoleLogs = ({ logs }: { logs: ConsoleMessage[] }) => (
  <div className="ConsoleLogs Code">
    {logs
      .map(item => item.data)
      .reverse()
      .map(item => <span key={item.msgId}>{`${item.name}: ${item.level}: ${JSON.stringify(item.msg)}`}</span>)}
  </div>
)

export default ConsoleLogs