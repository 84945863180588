import React from 'react'
import { QueueResponse } from '../../SocketMessage'
import moment from 'moment'
import useWeighbridgesInfo from '../../hooks/useWeighbridgesInfo'

type QueuePanelProps = {
  response?: QueueResponse
}
const QueuePanel: React.FC<QueuePanelProps> = ({ response }) => {
  const weighbridges = useWeighbridgesInfo()
  const isFresh = response && moment(response.iat).isAfter(moment().subtract(1, 'minute'))

  const [ticker, setTicker] = React.useState(0)
  React.useEffect(() => {
    const id = setTimeout(() => setTicker(Date.now()), 1000)
    return () => clearTimeout(id)
  }, [ticker])

  const getWeighbridgeName = React.useCallback((uid: string) => weighbridges.find(item => item.uid === uid)?.name, [weighbridges])

  return (
    <div className="QueuePanel">
      <h2>Global Queue</h2>

      {response && (
        <div className="list">
          <span className={`tag ${isFresh ? 'success' : 'warning'}`}>Updated {moment(response.iat).fromNow()}</span>
          <div className="Clients">
            {response.queue.map((item, index) => (
              <p key={item.client.uid + index} title={item.client.uid}>
                <span className={`tag ${item.client.type}`}>{item.client.type}</span>
                <span className="DeviceName">{item.client.name.split('@')[0]}</span>
                <span className="Line" />
                <span className="WeighbridgeTarget">{getWeighbridgeName(item.path)}</span>
              </p>
            ))}
          </div>
        </div>
      )}

      {!response && <p><em>No data.</em></p>}
    </div>
  )
}

export default QueuePanel