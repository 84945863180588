import React from 'react'
import { GlobalContext } from './state/reducer'
import Login from './components/Login/Login'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Header, { HeaderNavItem } from './components/common/Header/Header'
import Monitor from './components/Monitor/Monitor'
import PrivateRoute from './components/PrivateRoute'
import useAirPreloader from './hooks/useAirPreloader'

const LogrAirSystemMonitor = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { user, userDetails, expectSignedIn } = state
  const history = useHistory()
  const location = useLocation()
  const [signIn, signOutWithDispatch, signInFetching, error] = useAirPreloader(userDetails, dispatch)

  React.useEffect(() => {
    if (user && history) {
      const { from } = (location.state as any) || { from: { pathname: '/' } }
      history.replace(from);
    }
  }, [user, location.state, location.pathname, history])

  const publicNavItems: HeaderNavItem[] = React.useMemo(() => ([
    { label: '(System Monitor)', key: '/systemMonitor', disabled: true, action: () => { } },
    { label: 'Login', key: '/login', disabled: '/login' === location.pathname, action: () => history && history.replace({ pathname: '/login' }) },
  ]), [history, location.pathname])

  const privateNavItems: HeaderNavItem[] = React.useMemo(() => ([
    { label: '(System Monitor)', key: '/systemMonitor', disabled: true, action: () => { } },
    { label: 'System Monitor', key: '/monitor', action: () => history && history.replace({ pathname: '/monitor' }) },
    {
      label: 'Sign out',
      key: '/signout',
      action: signOutWithDispatch,
    },
  ]), [history, signOutWithDispatch])

  return (
    <>
      {!user && <Header navItems={expectSignedIn ? [] : publicNavItems} current={location.pathname}/>}
      {user && <Header navItems={privateNavItems} current={location.pathname}/>}

      <Switch>
        <Route path="/login">
          <Login user={user} signIn={signIn} fetching={signInFetching} error={error} />
        </Route>
        <PrivateRoute path="/">
          <Monitor />
        </PrivateRoute>
        <PrivateRoute path="/monitor">
          <Monitor />
        </PrivateRoute>
      </Switch>
    </>
  )
}

export default LogrAirSystemMonitor