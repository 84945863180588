import React from 'react'

function useDebounceValue<T>(value: T, delay: number): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  const [debouncing, setDebouncing] = React.useState(false)

  React.useEffect(() => {
    setDebouncing(true)

    const timer = setTimeout(() => {
      setDebouncedValue(value)
      setDebouncing(false)
    }, delay)

    return () => {
      clearTimeout(timer)
      setDebouncing(false)
    }
  }, [value, delay])

  return [debouncedValue, debouncing]
}

export default useDebounceValue