import React, { useEffect } from 'react'
import air from '../state/firebaseApp'
import {
  SIGN_IN,
  SIGN_OUT,
  USER_DETAILS_FETCHED,
  Actions
} from '../state/reducer'

import useFirebaseAuth from './useFirebaseAuth'
import useUserDetails from './useUserDetails'
import { UserDetails } from '../types'

type SignInFunction = (email: string, password: string) => void
type SignOutFunction = () => void
type FetchingState = boolean
type ErrorMessage = Error | undefined

const useAirPreloader = (userDetails: UserDetails|undefined, dispatch: React.Dispatch<Actions>): [SignInFunction, SignOutFunction, FetchingState, ErrorMessage] => {
  const [authUser, firebaseToken, signIn, signOut, signInFetching, error] = useFirebaseAuth(air, 500)

  const signOutWithDispatch = React.useCallback(() => {
    signOut()
    dispatch({ type: SIGN_OUT })
  }, [dispatch, signOut])

  // Login Handler
  React.useEffect(() => {
    if (authUser && firebaseToken && userDetails) {
      dispatch({ type: SIGN_IN, user: authUser, firebaseToken })
    }

  }, [dispatch, authUser, firebaseToken, userDetails])

  // User Details when user is available
  const [fetchedUserDetails] = useUserDetails(air, authUser)

  useEffect(() => {
    if (fetchedUserDetails) {
      dispatch({ type: USER_DETAILS_FETCHED, userDetails: fetchedUserDetails })
    }
  }, [dispatch, fetchedUserDetails])

  return [signIn, signOutWithDispatch, signInFetching, error]
}

export default useAirPreloader