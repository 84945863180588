import React from 'react'

type AirChannelServerPayloads = { type: 'last_message_updated_at', updatedAt: number }
  | { type: 'last_info_updated_at', updatedAt: number }
  | { type: 'last_weight_updated_at', updatedAt: number }
  | { type: 'status', status: 'online' | 'offline' }
  | { type: 'authenticate_confirmed' }
  | { type: 'authenticate_failed', data: string }
  | { type: 'authenticate_failed', data: string }
  | { type: 'INDICATOR_VALUE', line: string }
  | { type: 'info' }

const useWbcsClient = (weighbridgeUid?: string, token?: string, retryTimeout?: number) => {
  const [connected, setConnected] = React.useState(false)
  const [authenticated, setAuthenticated] = React.useState(false)
  const [line, setLine] = React.useState<string>()
  const [info, setInfo] = React.useState<any>()
  const [retry, setRetry] = React.useState(0)
  const [status, setStatus] = React.useState<'online'|'offline'>()
  const [lastMessageAt, setLastMessageAt] = React.useState<number>()
  const [lastInfoAt, setLastInfoAt] = React.useState<number>()
  const [lastWeightAt, setLastWeightAt] = React.useState<number>()
  const socketRef = React.useRef<WebSocket>()

  React.useEffect(() => {
    if (weighbridgeUid && token) {
      setConnected(false)
      setAuthenticated(false)
      setLine(undefined)
      setInfo(undefined)

      if (!socketRef.current) {
        socketRef.current = new WebSocket(`wss://monitor-service.air.logr.com.au/${weighbridgeUid}`)
      }

      const socket = socketRef.current

      socket.onopen = () => {
        socket.send(JSON.stringify({ type: 'authenticate', data: { token, type: 'wbcs_client' } }))
        setConnected(true)
      }

      socket.onclose = () => {
        setConnected(false)
        setAuthenticated(false)
        setTimeout(() => setRetry((prev: number) => prev + 1), retryTimeout || 5000)
      } // triggers reconnect after 5 seconds

      socket.onmessage = (evt) => {
        try {
          const parsed: AirChannelServerPayloads = JSON.parse(`${evt.data}`)

          switch (parsed.type) {
            case 'authenticate_failed':
              socket.close()
              setAuthenticated(false)
              break
  
            case 'authenticate_confirmed':
              setAuthenticated(true)
              break
  
            case 'INDICATOR_VALUE':
              setLine(parsed.line)
              break
  
            case 'info':
              setInfo(parsed)
              break
  
            case 'status':
              setStatus(parsed.status)
              break
  
            case 'last_message_updated_at':
              setLastMessageAt(parsed.updatedAt)
              break
  
            case 'last_info_updated_at':
              setLastInfoAt(parsed.updatedAt)
              break
  
            case 'last_weight_updated_at':
              setLastWeightAt(parsed.updatedAt)
              break
          }
        } catch (error) {
          console.log(evt)
        }
      }

      return () => {
        setConnected(false)
        setAuthenticated(false)
        setLine(undefined)
        setInfo(undefined)

        if (socketRef.current) {
          socketRef.current.close()
          socketRef.current = undefined
        }
      }
    }

  }, [token, weighbridgeUid, retry, retryTimeout])

  return {
    connected,
    authenticated,
    line,
    info,
    status,
    lastMessageAt,
    lastInfoAt,
    lastWeightAt,
  }
}

export default useWbcsClient