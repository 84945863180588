import { WeightMeasurement, IdentityReceived } from 'logr-air-lib'

export const measurementsToString = (measurements?: WeightMeasurement[]) => {
  if (measurements) {
    return measurements.reduce((prev, next) => [...prev, `${next.weight}${next.unit}`], [] as string[]).join(', ')
  }

  return ''
}

export const identitiesToString = (identities?: IdentityReceived[]) => {
  if (identities) {
    return identities
      .filter(item => item.identities.length > 0)
      .map(identityToString)
  }

  return ''
}

export const identityToString = (identity: IdentityReceived) => {
  return identity.identities.reduce((prev, next) => [...prev, next.value], [] as string[]).join(',')
}

export const cameraIdentityToUri = (identity: IdentityReceived) => {
  type CameraIdentity = { source: string, value: string, uri: string }
  return identity.identities.reduce((prev, next) => {
    const source = next.source
    const value = next.value
    const uri = next.references?.find(item => item.type === 'image_uri')?.value

    if (uri) {
      return [...prev, { source, value, uri }]
    }

    return prev
  }, [] as CameraIdentity[])
}

const cameraSources = ['anpr', 'anpr_alt']

export const nonCameraIdentityToUri = (identity: IdentityReceived) => {
  type NonCameraIdentity = { source: string, value: string }
  return identity.identities.reduce((prev, next) => {
    const source = next.source
    const value = next.value

    if (cameraSources.includes(source)) {
      return prev
    }

    return [...prev, { source, value }]
  }, [] as NonCameraIdentity[])
}
