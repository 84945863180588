import React from 'react';
import { SocketMessage } from '../SocketMessage'
import isUrl from 'is-url'

export const DEBUG_ALL_SESSIONS = 'get_sessions'
export const DEBUG_QUEUE = 'get_queue'

type WebsocketResponse = SocketMessage | undefined
type StatusCode = number
type SendMessageFunc = (msg: string) => void
type ReconnectFunc = () => void
type ErrorMessage = string | null
type useAirSocketType = (getToken: () => Promise<string>, url: string) => useAirSocketTypeReturnType
type useAirSocketTypeReturnType = [
  WebsocketResponse,
  StatusCode,
  SendMessageFunc,
  ReconnectFunc,
  ErrorMessage
]

export const useAirSocketFirebaseUser = (user: firebase.User | undefined, url: string): useAirSocketTypeReturnType => {
  const getToken = React.useCallback(async () => {
    if (user) {
      return await user.getIdToken()
    }

    return Promise.reject('No user available')
  }, [user])

  const [msg, readyState, sendMessage, reconnect, error] = useAirSocket(getToken, url)

  return [msg, readyState, sendMessage, reconnect, error]
}

const useAirSocket: useAirSocketType = (getToken: () => Promise<string>, url) => {
  const socketRef = React.useRef<WebSocket>()
  const [msg, setMsg] = React.useState<SocketMessage>()
  const [error, setError] = React.useState<string | null>(null)
  const [readyState, setReadyState] = React.useState<number>(-1)
  const [reconnectTicker, setReconnectTicker] = React.useState<number>(Date.now())
  const [authFailed, setAuthFailed] = React.useState(false)

  React.useEffect(() => {
    console.log('[reconnect]', authFailed ? '[failedAuth]' : '[authOk]', reconnectTicker / 1000, url)

    if (authFailed) {
      return
    }

    if (!isUrl(url)) {
      return;
    }

    setError(null)
    const socket = new WebSocket(url)
    socketRef.current = socket

    getToken()
      .then(token => {

        socket.onopen = () => {
          socket.send(JSON.stringify({ type: "version" }))
          socket.send(JSON.stringify({ type: "authenticate", data: { token } }))
          setReadyState(socket.readyState)
        }

        socket.onclose = () => {
          console.log('Your connection closed')
          setReadyState(socket.readyState)

          if (!authFailed) {
            setReconnectTicker(Date.now())
          }
        }

        socket.onerror = (err) => {
          console.log('Your connection had an error', err)
          setReadyState(socket.readyState)
        }

        socket.onmessage = (msg) => {
          try {
            setError(null)
            const parsed = JSON.parse(`${msg.data}`)

            switch (parsed.type) {
              case 'authenticate_failed':
                socket.close()
                setAuthFailed(true)
                break

              case 'authenticate_confirmed':
                // These are no longer necessary, server sends these on connection
                // socket.send(JSON.stringify({ type: DEBUG_ALL_SESSIONS }))
                // socket.send(JSON.stringify({ type: DEBUG_QUEUE }))
                break
            }

            setMsg(parsed)
          } catch (error) {
            setError(error.message)
          }
        }
      })

    return () => {
      socket.onopen = null
      socket.onclose = null
      socket.onerror = null
      socket.onmessage = null
      socket.close()
    }
  },
    [getToken, authFailed, url, reconnectTicker])

  return [
    msg,
    readyState,
    (msg: string) => socketRef.current?.send(msg),
    () => setReconnectTicker(Date.now()),
    error
  ]
}

export default useAirSocket