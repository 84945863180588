import React from 'react'
import classNames from 'classnames'
import useLocalStorage from '../../hooks/useLocalStorage'
import useDebounceValue from '../../hooks/useDebounceValue'
import TextInput from '../common/TextInput/TextInput'
import Logo from '../common/Logo'
import './Login.scss'

type LoginProps = {
  user?: firebase.User
  signIn: (email: string, password: string) => void
  fetching: boolean
  error?: Error
}

const Login: React.FC<LoginProps> = ({ user, signIn, fetching, error }) => {
  const [email, setEmail] = useLocalStorage('email', '')
  const [password, setPassword] = React.useState('')
  const [debouncedValue] = useDebounceValue(fetching, 500)
  const enabled = !debouncedValue
  const onSignIn = React.useCallback(() => signIn(email, password), [signIn, email, password])

  const noUser = !user
  const hasPassword = password !== ''

  return (
    <form className="Login" onSubmit={e => { e.preventDefault(); onSignIn() }}>
      <Logo height={80} />
      <div style={{ height: '30px' }} />
      {((noUser && enabled) || hasPassword) && (
        <div className="form entered">
          <TextInput
            value={email}
            onChange={setEmail}
            enabled={enabled}
            label="Email"
            orientation="vertical"
          />
          <TextInput
            value={password}
            onChange={setPassword}
            enabled={enabled}
            label="Password"
            type="password"
          />
          <div style={{ marginTop: '30px' }}>
            <button className={classNames('wide button', { 'disabled': !enabled })} onClick={onSignIn}>
              Log in
          </button>
          </div>
        </div>
      )}

      {error && <span className="Error">{error.message}</span>}
    </form>
  )
}

export default Login