import firebase from 'firebase/app'

export type QuerySnapshot = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

const CloudReducer = <T>(prev: T[], querySnapshot: QuerySnapshot|null): T[] => {
  if (querySnapshot === null) {
    return []
  }
  
  let records: T[] = [...prev]

  querySnapshot
    .docChanges()
    .forEach(change => {
      const record = change.doc.data() as T
      (record as any).uid = change.doc.id

      const filtered = records.filter(item => (item as any).uid !== change.doc.id)

      switch (change.type) {
        case 'added':
        case 'modified':
          records = [record, ...filtered]
          break

        case 'removed':
          records = filtered
          break
      }
    })

  return records
}

export default CloudReducer