import React from 'react'

function ReadyStateStatus(props: { readyState: number }) {
  const { readyState } = props
  let label: 'Connected'|'Connecting'|'Closed'|'Closing'|'?' = '?'

  if (readyState === WebSocket.OPEN) {
    label = "Connected"
  } else if (readyState === WebSocket.CONNECTING) {
    label = "Connecting"
  } else if (readyState === WebSocket.CLOSED) {
    label = "Closed"
  } else if (readyState === WebSocket.CLOSING) {
    label = "Closing"
  }

  const colours = {
    Connected: 'success',
    Connecting: 'warning',
    Closing: 'warning',
    Closed: 'danger',
    '?': 'danger',
  }

  return (
    <div className={`tag ${colours[label]}`}>
      {label}
    </div>
  )
}

export default ReadyStateStatus