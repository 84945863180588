import React from 'react'
import Logo from '../Logo'
import './Header.scss'
import classNames from 'classnames'

export type HeaderNavItem = {
  label: string
  key: string
  disabled?: boolean
  action: () => void
}

interface HeaderProps {
  navItems: HeaderNavItem[]
  current: string
}

const Header: React.FC<HeaderProps> = ({ navItems, current }) => {
  return (
    <header className="Header">
      <div className="logoSection"><Logo height={32} /></div>
      <nav>
        {navItems.map(({ label, key, disabled, action }) => (
          <div className={classNames('navlink', { disabled, current: current === key })} key={label}>
            <div className="navlinktext" onClick={() => !disabled && action()}>
              {label}
            </div>
          </div>
        ))}
      </nav>
    </header>
  )
}

export default Header