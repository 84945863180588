import React from 'react'
import 'firebase/firestore'
import { UserDetails } from '../types'

export type DocumentSnapshot = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>

const userDetailReducer = (prev: UserDetails|null, action: DocumentSnapshot|null): UserDetails|null => {
  if (action) {
    return {
      uid: action.id,
      ...action.data(),
    } as UserDetails
  }

  return null
}

const useUserDetails = (firebaseApp: firebase.app.App, user?: firebase.User): [UserDetails|null, Error?] => {
  const [error, setError] = React.useState<Error>()
  const [detail, setDetail] = React.useReducer(userDetailReducer, null)

  // Gets access details
  React.useEffect(() => {
    setDetail(null)

    if (!user) {
      return
    }

    try {
      if (firebaseApp) {
        setError(undefined)

        return firebaseApp
          .firestore()
          .collection('roles')
          .doc(user.uid)
          .onSnapshot(setDetail)
      }
    } catch (e) {
      setError(e)
    }
  }, [firebaseApp, user])

  return [detail, error]
}

export default useUserDetails