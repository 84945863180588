import {
  IdentityReceived,
  ReceivedStableWeight,
  ClaimBecameAvailable,
  DriverClaimAndIdentityConfirmed,
  Receipt,
} from 'logr-air-lib'

export const VERSION = 'version'
export const AUTHENTICATE_CONFIRMED = 'authenticate_confirmed'
export const UPDATED_QUEUE = 'updated_queue'
export const UPDATED_SESSIONS = 'updated_sessions'
export const CONSOLE = 'console'
export const DEBUG_UPDATED_SESSION = 'updated_session'
export const DEBUG_UPDATED_SESSION_CLIENTS = 'updated_session_clients'

export type VersionMessage = {
  type: typeof VERSION,
  data: {
    version: string,
    sync: string
  }
}

export type AuthConfirmedMessage = {
  type: typeof AUTHENTICATE_CONFIRMED
  data: object
}

export type Client = {
  uid: string
  name: string
  type: 'driver' | 'identity'
  iat: number
}

export type QueueResponse = {
  iat: number
  queue: {
    path: string
    client: Client
  }[]
}
export type UpdatedQueueMessage = {
  type:  typeof UPDATED_QUEUE,
  data: QueueResponse[]
}

export type SessionState = {
  location?: any
  owner?: any
  transactionId?: string
  uid: string
  state: string
  communicationType?: string
  identityRequests?: string[]
  identitiesReceived?: IdentityReceived[]
  // startTime?: number
  endTime?: number
  stableWeight?: ReceivedStableWeight
  availableClaim?: ClaimBecameAvailable
  claimantSocketId?: any
  claimant?: {
    uid: string
    name: string
  }
  confirmedClaim?: DriverClaimAndIdentityConfirmed
  receipt?: Receipt
  timeoutDurations?: {
    requestIdentity?: number
    driverConfirm?: number
    weighbridgeReceipt?: number
  }
}

// IdentityReceived,
// ReceivedStableWeight,
// ClaimBecameAvailable,
// DriverClaimAndIdentityConfirmed,
// Receipt,
export type ActiveSession = {
  uid: string
  state: SessionState
  clients?: Client[]
}

export type ActiveSessionResponse = {
  iat: number
  sessions: ActiveSession[]
}

export type UpdatedSessionsMessage = {
  type:  typeof UPDATED_SESSIONS,
  data: {
    iat: number
    sessions: {
      uid: string
      state: SessionState
      clients: Client[]
    }[]
  }
}

export type ConsoleMessage = {
  type: typeof CONSOLE
  data: {
    msgId: string
    name: string
    level: 'log' | 'info' | 'error'
    msg: any
  }
}

export type SocketMessage = VersionMessage
  | AuthConfirmedMessage
  | UpdatedQueueMessage
  | UpdatedSessionsMessage
  | ConsoleMessage